import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash';
import PropTypes from "prop-types";


const BlogList = ({ description, title, blogsURL, blogsMaxCount,moreBlogsButton }) => {

  const data = useStaticQuery(graphql`
    query Blogs {

        blogs: allStrapiBlog(sort: {fields: [index,date], order: [ASC,DESC]}) {
          nodes {
            id
            slug
            title
            body {
              data {
                body
              }
            }
            description
            date
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }


    }
  `)

  let blogs =   data.blogs.nodes.map( item =>
        _.merge( _.pick(item,['id','slug','title','description','date']),
                 _.pick(item.body.data,['body'] ),
                 { image: item.image.localFile })
  )
  let now = new Date();
  let today = moment(now).format('YYYY-MM-DD');
  //console.log( today );
  return (
      <div className="blogs-section section" id="Blogs">
        <div className="container">
          <div className="section-head">
            <h2>{title}</h2>
            <h3>{description}</h3>
          </div>
          <ul className={`blogs-list ${data.length < 5 ? "few-blogs" : ""}`}  >
            {blogs.filter(item => item.date <= today ).slice(0,blogsMaxCount).map((item, index) => {
              //console.log( today, item.date);
              return (
                <li key={index} className="item">
                  <div className="inner">
                    <Link className="link" to={`/${item.slug}`} />

                    {item.image ? (
                      <GatsbyImage image={item.image.childImageSharp.gatsbyImageData}  alt="image" objectFit="cover"  objectPosition="50% 50%"   />
                    ) : (
                      <div className="no-image"></div>
                    )}
                    <div className="details">
                      <h3 className="title">{item.title}</h3>
                      <span className="date">
                        <i className="fas fa-calendar-alt"></i>{" "}
                        {moment(item.date, "YYYY-MM-DD").fromNow()}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          { moreBlogsButton ? <div className="see-more"><Link to={blogsURL}><span>More Blogs</span></Link></div> : <div></div> }
        </div>
      </div>
    );
}

BlogList.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    moreBlogsButton: PropTypes.bool,
    blogsURL: PropTypes.string,
    blogsMaxCount: PropTypes.number,
}

BlogList.defaultProps = {
  title: "News & Blogs",
  description: "Our Latest News, Articles and Blogs",
  blogsURL: "/blogs/",
  blogsMaxCount: 99,
  moreBlogsButton: false
}

export default BlogList;
