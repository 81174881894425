import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Layout from "../components/layout";
import Seo from "../components/seo";
import _ from 'lodash';
import MDRender from "../components/mdrender"
import { withPrefix } from "gatsby"
import HeroImage from "../components/HeroImage/heroimage";
import ServiceMenu from '../components/servicemenu';
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import Parts from '../components/parts';
import BlogList from '../components/bloglist'
const isBrowser = typeof document !== "undefined"




export default function BlogPost({ data }) {


    let mapBlogdata =  _.merge( _.pick(data.blog,['id','date', 'description','title','slug']),
                                _.pick(data.blog.body.data,['body']),
                                { image: data.blog.image.localFile })

    let solutionspageData = _.merge( _.pick(data.solutionspage.nodes[0],['id','description','title','slug','section','page','date']),
                                   _.pick(data.solutionspage.nodes[0].body.data,['body']) )

    let partsData = _.orderBy( data.blog.parts.map( item => {
                  //console.debug( item );
                  return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                                  { image: (item.image) ? item.image.localFile : null },
                                  { text: (item.text && item.text.data ) ? ( item.text.data.text ) : "" })
                }),["order"],["asc"]  )

    const useHero = false; //( mapBlogdata.image !==null)
    const background = true;
    if (useHero) {
        return (
          <Layout header="Blog" >
            <Seo  title={ mapBlogdata.title } descripton={mapBlogdata.descripton}  keywords={["Blog Page"]} />
            <div className="site-container blog-post">
              <div className="container">
                <HeroImage title={mapBlogdata.title} description={mapBlogdata.description} image={withPrefix(mapBlogdata.image.childImageSharp.gatsbyImageData.images.fallback.src)} />
                <div className="details">
                  <h1 className="title">{mapBlogdata.title}</h1>
                  <span className="date">
                    <i className="fas fa-calendar-alt"></i>{" "}
                    {moment(mapBlogdata.date,  "YYYY-MM-DD").format("LL")}
                  </span>
                  <div>
                      <h2 className="description">{mapBlogdata.description}</h2>
                  </div>
                  <div>
                      <MDRender mdstring={mapBlogdata.body} />
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        );
      } else if (background) {
        const image = getImage(withPrefix(mapBlogdata.image));
        const bgImage = convertToBgImage(image);
        return (
          <Layout header="Blog" >
            <Seo  title="Blogs"  keywords={["Blog Page"]} />
            <div className="site-container">
              <div className="container">
                <div className="blog-page">
                      <BackgroundImage        Tag="section"   {...bgImage}     preserveStackingContext       >
                        <div className="blog-content text-white overlay overlay-dark p-3">
                          <h1 className="title">{mapBlogdata.title}</h1>
                          <h2 className="description">{mapBlogdata.description}</h2>
                          <span className="date">
                            <i className="fas fa-calendar-alt"></i>{" "}
                              {moment(mapBlogdata.date,  "YYYY-MM-DD").format("LL")}
                          </span>
                          <div className="row">
                              <div className="col blog-details">
                                <div className="blog-body ">
                                    <MDRender mdstring={mapBlogdata.body} />
                                </div>
                              </div> {/*blog-details */}

                              <div className="col blog-detail">
                                  <GatsbyImage  className="blog-image"  image={image} alt="image" objectFit="cover" objectPosition="50% 50%" />
                              </div>
                          </div> {/* row */}
                          <div className="row blog-footer p-3">
                            <div className="col blog-parts">
                                <Parts key="Parts" anchor="parts" parts={partsData}/>
                            </div>
                            <BlogList key="Blogs"></BlogList>
                            <ServiceMenu  key="ServiceMenu" title="Our Products and Services" ></ServiceMenu>
                          </div>
                        </div> {/*blog-content*/}
                      </BackgroundImage>
                </div>  {/* blog-post */}
              </div> {/* container */}
            </div> {/* blog-page */}
          </Layout>
        );
    } else {
      return (
        <Layout header="Blog" >
          <Seo  title="Blogs"  keywords={["Blog Page"]} />
          <div className="site-container blog-post">
            <div className="container">
              {mapBlogdata.image ? (
                <GatsbyImage image={mapBlogdata.image.childImageSharp.gatsbyImageData}
                  alt="image" objectFit="cover" objectPosition="50% 50%"  />
              ) : ( <div className="no-image"></div> )}
              <div className="details">
                <h1 className="title">{mapBlogdata.title}</h1>
                <span className="date">
                  <i className="fas fa-calendar-alt"></i>{" "}
                  {moment(mapBlogdata.date,  "YYYY-MM-DD").format("LL")}
                </span>
                <div>
                    <h2 className="description">{mapBlogdata.description}</h2>
                </div>
                <div>
                    <MDRender mdstring={mapBlogdata.body} />
                </div>
              </div>
              <Solution  key="Solution" description={solutionspageData.description}  link={false}></Solution>
            </div>
          </div>
        </Layout>
      );
    }
}

export const pageQuery = graphql`
  query ($id: String!) {

    blog: strapiBlog(id: { eq: $id }) {
      body {
        data {
          body
        }
      }
      date
      description
      id
      parts {
        id
        order
        page
        title
        text {
          data {
            text
          }
        }
        layouttype
        partId
        anchor
        options {
          parallax
          height
          centre
          centretitle
          margin          
        }
        image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      title
      slug
    }

    solutionspage: allStrapiPage(filter: {title: {eq: "Solutions"}}) {
      nodes {
        id
        title
        strapi_id
        slug
        section
        page
        description
        date
        body {
          data {
            body
          }
        }
      }
    }

  }
`;
